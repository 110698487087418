<template>
    <div class="w-full h-auto mb-20">
        <div>
            <img class="h-120" src="../pictures/Contact/contact-title.png" />
        </div>
        <div class="text-xl font-bold pt-10">鹦果智慧校园</div>
        <div class="grid grid-rows-3 grid-cols-6 gap-4 mt-5">
            <div class="row-span-1 col-start-2 col-end-4">
                <a-col :span="1"><img class="w-6" src="../pictures/Contact/contact-phone.png" /></a-col>
                <a-col>
                    <div class="text-base text-left align-middle ml-8">
                        400-848-9166
                    </div>
                </a-col>
            </div>
            <div class="row-span-1 col-start-4 col-end-6">
                <a-col :span="1"><img class="w-6" src="../pictures/Contact/contact-Email.png" /></a-col>
                <a-col>
                    <div class="text-base text-left align-middle ml-8">
                        ygschools@163.com
                    </div>
                </a-col>
            </div>
            <div class="row-span-1 col-start-2 col-end-4">
                <a-col :span="1"><img class="w-6" src="../pictures/Contact/contact-time.png" /></a-col>
                <a-col>
                    <div class="text-base text-left align-middle ml-8">
                        周一到周日 9:00-18:00
                    </div>
                </a-col>
            </div>
            <div class="row-span-2 col-start-4 col-end-6">
                <div class="text-base text-left">
                    建议您采用163或QQ信箱与我们联系，我们会在24小时之内回复您的邮箱，如未在此时间内收到回复，建议您拨打客服电话联系我们。
                </div>
            </div>
            <div class="row-span-1 col-start-2 col-end-4">
                <a-col :span="1"><img class="w-6" src="../pictures/Contact/contact-address.png" /></a-col>
                <a-col>
                    <div class="text-base text-left align-middle ml-8">
                        杭州市拱墅区石祥路269号1号浙大城市学院北秀楼401室
                    </div>
                </a-col>
            </div>
        </div>
        <div id="container"></div>
    </div>
</template>

<script>
    import AMapLoader from '@amap/amap-jsapi-loader';

    export default {
        data() {
            return {

            };
        },
        mounted() {
            AMapLoader.load({
                "key": "e02063e10ca5542edd1ec9cafce2ad6b",          // 申请好的Web端开发者Key，首次调用 load 时必填
                "version": "1.4.15",                                // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                "plugins": [
                    'AMap.InfoWindow',
                    'AMap.PlaceSearch',
                    'AMap.Scale',
                    'AMap.OverView',
                    'AMap.ToolBar',
                    'AMap.MapType',
                    'AMap.PolyEditor',
                    'AMap.CircleEditor',
                    'AMap.Geolocation'
                ],                               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                "AMapUI": {                                         // 是否加载 AMapUI，缺省不加载
                    "version": '1.1',                               // AMapUI 缺省 1.1
                    "plugins": [],                                   // 需要加载的 AMapUI ui插件
                },
                "Loca": {                                            // 是否加载 Loca， 缺省不加载
                    "version": '1.3.2'                              // Loca 版本，缺省 1.3.2
                },
            }).then((AMap) => {
                var map = new AMap.Map('container', {
                    zoom: 17,                                        //级别
                    center: [120.15588, 30.332011],                 //中心点坐标
                    viewMode: '3D'                                   //使用3D视图
                });
                map.addControl(new AMap.ToolBar());
                map.addControl(new AMap.Scale());
                map.addControl(new AMap.InfoWindow());
                var marker = new AMap.Marker({
                    map: map,
                    position: [120.15705, 30.331111]//点所在位置
                });
                var infoWindow = new AMap.InfoWindow({
                    content: "地址：杭州市拱墅区石祥路269号1号浙大城市学院北秀楼401室<br/>电话：400-848-9166"//信息窗体的内容
                });
                infoWindow.open(map, marker.getPosition());
            }).catch(e => {
                console.log(e);
            })
        },
        methods: {

        },
    };
</script>

<style scoped>
    #container {
        padding: 0px;
        width: 70%;
        height: 600px;
        margin-left: 15%;
        margin-top: 25px;
    }
</style>