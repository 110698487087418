<template>
  <div class="w-full h-auto mb-20">
    <div>
      <img class="h-120" src="../pictures/About/about-title.png" />
    </div>
    <div class="text-xl font-bold pt-10">PLATFORM LOCATION</div>
    <div class="grid">
      <div class="text-base mt-5 w-5/12 justify-self-center self-center">
        平台现有定位
      </div>
      <div class="text-blue-600 text-3xl font-extrabold">—</div>
    </div>
    <div class="grid pt-10 grid-rows-2 grid-cols-6 gap-16">
      <div class="row-span-2 col-start-2 col-end-4">
        <img src="../pictures/About/about-location.png" />
      </div>
      <div class="row-span-1 col-start-4 col-end-6">
        <div class="text-xl text-left font-bold">平台定位</div>
        <div class="grid">
          <div class="text-base text-left mt-2">
            1、基于K12领域，运用物联网及软件技术实现学生成长、平安校园、智慧管理、家校互动和在线学习的综合管理平台。
          </div>
          <div class="text-base text-left mt-2">
            2、关注学生校内校外成长轨迹。
          </div>
          <div class="text-base text-left mt-2">
            3、面向家长和老师的SNS平台。
          </div>
        </div>
      </div>
      <div class="row-span-1 col-start-4 col-end-6">
        <div class="text-xl text-left font-bold">平台特点</div>
        <div class="grid">
          <div class="text-base text-left mt-2">
            1、校园物联网平台。
          </div>
          <div class="text-base text-left mt-2">
            2、“家、校、生、教”的大数据平台。
          </div>
          <div class="text-base text-left mt-2">
            3、共享教育能力平台。
          </div>
        </div>
      </div>
    </div>

    <div class="text-xl font-bold pt-20">STRATEGIC TARGET</div>
    <div class="grid">
      <div class="text-base mt-5 w-5/12 justify-self-center self-center">
        公司战略目标
      </div>
      <div class="text-blue-600 text-3xl font-extrabold">—</div>
    </div>
    <div class="grid justify-items-center items-center">
      <div class="
          grid grid-cols-4
          justify-items-center
          items-center
          mt-10
          w-3/4
          gap-x-20
        ">
        <div class="grid grid-rows-2 gap-y-0 justify-items-center items-center shadow-lg h-80 w-60">
          <img src="../pictures/About/about-goal1.png" class="w-20 hover:scale-125 transition ease-out duration-700" />
          <div class="w-4/5">
            <div class="text-lg font-bold">企业使命</div>
            <div class="text-base mt-3">值因育果</div>
            <div class="text-base">幸福成长</div>
          </div>
        </div>
        <div class="grid grid-rows-2 gap-y-0 justify-items-center items-center shadow-lg h-80 w-60">
          <img src="../pictures/About/about-goal2.png" class="w-20 hover:scale-125 transition ease-out duration-700" />
          <div class="w-4/5">
            <div class="text-lg font-bold">企业愿景</div>
            <div class="text-base mt-3">成为中小学生幸福教育的好伙伴、学校管理的好助手</div>
          </div>
        </div>
        <div class="grid grid-rows-2 gap-y-0 justify-items-center items-center shadow-lg h-80 w-60">
          <img src="../pictures/About/about-goal3.png" class="w-20 hover:scale-125 transition ease-out duration-700" />
          <div class="w-4/5">
            <div class="text-lg font-bold">核心价值观</div>
            <div class="text-base mt-3">创新 合作</div>
            <div class="text-base">进取 担当</div>
          </div>
        </div>
        <div class="grid grid-rows-2 gap-y-0 justify-items-center items-center shadow-lg h-80 w-60">
          <img src="../pictures/About/about-goal4.png" class="w-20 hover:scale-125 transition ease-out duration-700" />
          <div class="w-4/5">
            <div class="text-lg font-bold">经营理念</div>
            <div class="text-base mt-3">以经营砖石为理念，打造行业最具影响力的管理系统软件</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
  };
</script>