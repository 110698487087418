var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"grid items-center justify-items-center"},[_c('div',{class:[
        'fixed',
        'z-50',
        'top-0',
        'w-full',
        'bg-white',
        'h-16',
        'transition-colors',
        'duration-600',
        'transition-height',
      ]},[_c('div',{class:[
          'grid',
          'grid-cols-12',
          'items-center',
          'justify-items-center',
          'mt-4',
          'duration-600',
          'transition-spacing',
        ]},[_c('img',{staticClass:"col-span-4 h-8",attrs:{"src":_vm.logo}}),_vm._l((_vm.names),function(name,index){return _c('div',{key:index,class:[
            'font-bold',
            'text-base',
            'cursor-pointer',
            'hover:text-black',
            'text-black',
          ],style:({ color: index == _vm.active ? '#409af4' : '',borderBottom:index==_vm.active?'3px solid #409af4':'' }),on:{"click":function($event){return _vm.scrollTo(index)}}},[_vm._v(" "+_vm._s(name)+" ")])})],2)])]),_c('router-view',{staticClass:"pt-16"}),_c('Bottom')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }