import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/components/Home"
import About from '@/components/About'
import Contact from '@/components/Contact'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/aboutyingle',
        component: About,
    },
    {
        path: '/contactus',
        component: Contact
    }
]


export default new VueRouter({
    mode: 'history',
    routes
})
