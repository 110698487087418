<template>
  <VueSlickCarousel v-bind="settings" class="carousel" ref="carousel">
    <img v-for="(picture, index) in pictures" :key="index" :src="picture" class="w-600" draggable="false" />
  </VueSlickCarousel>

</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  import "vue-slick-carousel/dist/vue-slick-carousel.css";
  import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

  export default {
    components: { VueSlickCarousel },
    data() {
      return {
        pictures: this.getPictures(),
        settings: {
          dots: false,
          focusOnSelect: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          touchThreshold: 5,
          autoplay: true,
          pauseOnDotsHover: true,
          pauseOnFocus: true,
          arrows: false,
        },
      };
    },
    methods: {
      getPictures() {
        let pictures = [];
        let imagesContext = require.context(
          "@/pictures/Home",
          false,
          /^\.\//
        );
        imagesContext.keys().forEach((image) => {
          pictures.push(imagesContext(image));
        });
        return pictures;
      },
      prev() {
        this.$refs.carousel.prev();
      },
      next() {
        this.$refs.carousel.next();
      },
    },
  };
</script>

<style scoped>
  .carousel {
    grid-column-start: 2;
  }
</style>