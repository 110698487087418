<template>
  <div id="app">
    <!-- 导航栏 -->
    <div class="grid items-center justify-items-center">
      <div :class="[
          'fixed',
          'z-50',
          'top-0',
          'w-full',
          'bg-white',
          'h-16',
          'transition-colors',
          'duration-600',
          'transition-height',
        ]">
        <div :class="[
            'grid',
            'grid-cols-12',
            'items-center',
            'justify-items-center',
            'mt-4',
            'duration-600',
            'transition-spacing',
          ]">
          <img :src="logo" class="col-span-4 h-8" />
          <div v-for="(name, index) in names" :key="index" :class="[
              'font-bold',
              'text-base',
              'cursor-pointer',
              'hover:text-black',
              'text-black',
            ]" :style="{ color: index == active ? '#409af4' : '',borderBottom:index==active?'3px solid #409af4':'' }"
            @click="scrollTo(index)">
            {{ name }}

          </div>
        </div>
      </div>
    </div>
    <router-view class="pt-16"></router-view>
    <Bottom></Bottom>
  </div>
</template>

<script>
  import Bottom from "./components/Bottom";

  export default {
    name: "App",
    components: {
      Bottom
    },
    data() {
      return {
        names: ["首页", "关于鹦果", "联系我们"],
        path: ['/', '/aboutyingle', '/contactus'],
        logo: require("@/pictures/Logo/logo.png"),
        active: 0,
      };
    },
    created() {
      const a = this.$router.currentRoute.path
      this.active = this.path.indexOf(a)
    },
    methods: {
      scrollTo(index) {
        this.active = index
        this.$router.push({
          path: this.path[index]
        })
      },
    },
  };
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>