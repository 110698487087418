<template>
    <div class="bg-black from-left to-right w-full h-auto pt-5 pb-5">
        <div class="text-gray-600">鹦果微信公众号</div>
        <div class="flex justify-center pt-5">
            <img class="w-20" src="../pictures/Bottom/gzh.png" />
        </div>
        <div class="text-gray-600 pt-2">©Copyright {{year}} {{company}}</div>

        <div style="width:220px;margin:0 auto; padding:5px 0;">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002016868"
                class="text-gray-600">
                <img src="../pictures/Bottom/ba.png" style="float:left;" />
                浙公网安备 33011002016868号
            </a>
        </div>
        <a class="text-gray-600" :href="beian_url" target="_blank">{{beian}}</a>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                company: '杭州二牛科技有限公司',
                year: '2022',
                beian: '浙ICP备2022034997号-1',
                beian_url: 'https://beian.miit.gov.cn/',
                privacy_policy_url: './static/privacy_policy.html'
            }
        }
    }
</script>