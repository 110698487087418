import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import './css/tailwindcss.css'
//全局配置ant-design-vue
//安装下载npm install ant-design-vue --save
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Antd);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
